<template>
  <div class="workout-chart" :class="{ 'workout-chart--is-lean': isLean }" v-show="hasData" ref="chart">
    <!--    <div v-if="showWBalSettings || $slots.right" class="is-flex is-justify-content-end pr-3">-->
    <!--      <div v-if="showWBalSettings" class="wbal-settings-panel">-->
    <!--        <WBalSettings :hideWBalCalcButton="hideWBalCalcButton" alignment="right" />-->
    <!--      </div>-->
    <!--      <slot name="right"></slot>-->
    <!--    </div>-->

    <svg :id="`workout-svg-${id}`" width="100%" :height="height" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <mask :id="`wBalMask-${id}`">
          <rect :x="x(0)" :y="y(maxValueY) - 2" :width="chartWidth" :height="chartHeight + 2" fill="white" />
        </mask>
      </defs>
      <g class="workout-details-chart-axis" :id="`workout-axis-${id}`"></g>
      <g class="lines-y">
        <text
          v-show="showAxisTitles"
          :id="`workout-axis-ftp-${id}`"
          transform="rotate(-90.000000)"
          text-anchor="middle"
          :font-size="fontSize"
          :fill="labelsColor"
          :x="-y(100)"
          :y="12"
        >
          <tspan>{{ isHRSegments ? "HR-T" : "FTP" }}</tspan>
        </text>
        <rect
          v-for="(line, index) in linesY"
          :width="line.width"
          :x="line.x"
          :y="line.y"
          :fill="line.fill"
          :height="line.height"
          :key="index"
        />
      </g>
      <g v-if="mainWork.isDetected" class="main-work" @mouseover="onHoverMainWorkText" @mouseout="onHoverMainWorkText">
        <text
          text-anchor="middle"
          font-size="18px"
          fill="#A5A9AC"
          style="cursor: default"
          :x="x(mainWork.startTime + (mainWork.endTime - mainWork.startTime) / 2)"
          :y="y(mainWork.posY + 5)"
        >
          <tspan>{{ $t("Main Work") }}</tspan>
        </text>
        <path
          v-if="mainWork.isDetected"
          stroke-linecap="round"
          fill="none"
          stroke="#A5A9AC"
          stroke-width="2"
          stroke-dasharray="12px 6px"
          stroke-dashoffset="7px"
          :d="sectionLine"
          :mask="`url(#main-work-line)`"
        ></path>
      </g>
      <g class="segments">
        <template v-if="isHRSegments">
          <path v-for="(p, i) in segmentsPathArray" :key="i" :fill="p.color" :d="p.d"></path>
        </template>
        <template v-else>
          <path :fill="segmentsColor" :d="segmentsPathString"></path>
        </template>
      </g>
      <!--      <path-->
      <!--        v-if="showWBalSettings && showWBal && wPrime && criticalPower"-->
      <!--        fill="none"-->
      <!--        stroke="red"-->
      <!--        stroke-width="2"-->
      <!--        stroke-dasharray="4"-->
      <!--        :d="wBalLine"-->
      <!--        :mask="`url(#wBalMask-${id})`"-->
      <!--      ></path>-->
    </svg>
  </div>
</template>

<script>
import Vue from "vue"
import * as Sentry from "@sentry/vue"
import { getSegmentsPower, wPrimeBalanceFroncioniSkibaClarke } from "@trainerday/cycling-metrics"
import { standardFtpArrayToWattsArray, hrPercentToHeartRateZone, INTERVALS_STYLES } from "@trainerday/cycling-converter"
import { scaleLinear } from "d3-scale"
import { extent } from "d3-array"
import { line, area } from "d3-shape"
import { select } from "d3-selection"
import { axisLeft, axisBottom } from "d3-axis"
// import WBalSettings from "@/shared/components/WBalSettings"
import { mapState } from "vuex"
import { debounce } from "underscore"
import "d3-transition"
import workoutChartHoverPolygons from "@/mixins/workoutChartHoverPolygons"

const MAX_VALUE_Y = 200
const PADDING = { top: 10, bottom: 40, left: 70, right: 15 }
const PADDING_MOBILE = { top: 10, bottom: 30, left: 58, right: 10 }
const LABELS_COLOR = "#2B2C2E" // '#D7DADF'
const LINE_GRAY = "#D7DADF"
const LINE_YELLOW = "#ffb825"
const FTP_SEGMENT_COLOR = "#2C68DE"
const OUTDOORS_SEGMENT_COLOR = "#144bb8"
const HR_SEGMENT_COLOR = "#808080"
const ZONE_COLORS = {
  Z1: "#a8e9c2",
  Z2: "#b1d3f3",
  Z3: "#c3abf2",
  Z4: "#ffe2b2",
  Z5: "#ffaead",
  Z6: "#7f7f7f"
}
const isMobile = () => window.innerWidth <= 767

export default Vue.extend({
  name: "ChartD3",
  mixins: [workoutChartHoverPolygons],
  // components: {
  //   WBalSettings
  // },
  data() {
    return {
      svg: null,
      fullWidth: 0,
      fullHeight: 0,
      maxValueY: MAX_VALUE_Y,
      labelsColor: LABELS_COLOR,
      isMobile: false,
      mainWork: {
        isDetected: false,
        startIndex: null,
        startTime: null,
        endIndex: null,
        endTime: null,
        posY: null
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      debouncedScale: () => {}
    }
  },
  props: {
    id: {
      type: Number,
      default: 0
    },
    isLean: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => ({
        height: 300,
        mobileHeight: 200
      })
    },
    mobileHeight: {
      type: Number,
      default: 200
    },
    isHRSegments: {
      type: Boolean,
      default: false
    },
    isOutdoorsSegments: {
      type: Boolean,
      default: false
    },
    segments: {
      type: Array,
      default: () => []
    },
    zones: {
      type: Array,
      default: () => []
    },
    hideWBalCalcButton: {
      type: Boolean,
      default: false
    },
    showWBalSettings: {
      type: Boolean,
      default: true
    },
    showAxisTitles: {
      type: Boolean,
      default: true
    },
    showMainWork: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapState(["tdUser"]),
    criticalPower() {
      return this.tdUser.criticalPower
    },
    wPrime() {
      return this.tdUser.wPrime
    },
    segmentsColor() {
      if (this.isHRSegments) {
        return HR_SEGMENT_COLOR
      }

      if (this.isOutdoorsSegments) {
        return OUTDOORS_SEGMENT_COLOR
      }

      return FTP_SEGMENT_COLOR
    },

    fontSize() {
      return this.isMobile ? 12 : 16
    },

    padding() {
      return this.isMobile ? PADDING_MOBILE : PADDING
    },

    width() {
      return this.fullWidth
    },

    chartWidth() {
      const { padding } = this
      return this.width ? this.width - padding.right - padding.left : 0
    },

    height() {
      return this.fullHeight
    },

    chartHeight() {
      const { padding } = this
      return this.height ? this.height - padding.top - padding.bottom : 0
    },

    x() {
      const { width, padding } = this

      return scaleLinear()
        .domain(extent(this.segmentsData, ([time]) => time))
        .range([padding.left, width - padding.right])
    },

    y() {
      const { height, maxValueY, padding } = this

      return scaleLinear()
        .domain([0, maxValueY])
        .range([height - padding.bottom, padding.top])
    },

    linesY() {
      const { x, y, chartWidth, maxValueY } = this
      const middle = maxValueY / 2

      const shiftX = 3

      return [
        { width: chartWidth + shiftX, x: x(0) - shiftX, y: y(maxValueY), height: "1", fill: LINE_GRAY },
        { width: chartWidth + shiftX, x: x(0) - shiftX, y: y(middle * 1.25), height: ".6", fill: LINE_GRAY },
        { width: chartWidth + shiftX, x: x(0) - shiftX, y: y(middle * 1.5), height: "1", fill: LINE_GRAY },
        { width: chartWidth + shiftX, x: x(0) - shiftX, y: y(middle * 1.75), height: ".6", fill: LINE_GRAY },
        { width: chartWidth + shiftX, x: x(0) - shiftX, y: y(middle), height: "2", fill: LINE_YELLOW },
        {
          width: chartWidth + shiftX,
          x: x(0) - shiftX,
          y: y(maxValueY - middle * 1.75),
          height: ".6",
          fill: LINE_GRAY
        },
        { width: chartWidth + shiftX, x: x(0) - shiftX, y: y(maxValueY - middle * 1.5), height: "1", fill: LINE_GRAY },
        {
          width: chartWidth + shiftX,
          x: x(0) - shiftX,
          y: y(maxValueY - middle * 1.25),
          height: ".6",
          fill: LINE_GRAY
        },
        { width: chartWidth + shiftX, x: x(0) - shiftX, y: y(0), height: "1", fill: LINE_GRAY }
      ]
    },

    hasData() {
      return !!this.segments.length
    },

    wattsSegments() {
      return standardFtpArrayToWattsArray(this.segments, this.criticalPower)
    },

    wPrimeBalance() {
      return wPrimeBalanceFroncioniSkibaClarke(
        [...getSegmentsPower(this.wattsSegments)],
        this.criticalPower,
        this.wPrime
      )
    },

    wBalLineData() {
      return this.wPrimeBalance.map((power, index) => {
        const currentSecond = index
        return [currentSecond, power / 100]
      })
    },

    segmentsData() {
      let timeAcc = 0
      let color = this.isOutdoorsSegments ? OUTDOORS_SEGMENT_COLOR : FTP_SEGMENT_COLOR

      return this.segments.reduce((acc, [time, start, end, cadenceStart, cadenceEnd, , , slope], index) => {
        let zoneData = null

        // define zone and color
        if (this.isHRSegments) {
          if (this.zones.length) {
            zoneData = hrPercentToHeartRateZone(start, this.zones)
            color = ZONE_COLORS[`Z${zoneData.zoneNumber}`]
          } else {
            color = HR_SEGMENT_COLOR
          }
        }

        acc.push([timeAcc, start, cadenceStart, slope, index, color, zoneData])
        timeAcc = timeAcc + time
        acc.push([timeAcc, end, cadenceEnd, null, index, color, zoneData])
        return acc
      }, [])
    },

    /** Returns a single string path for all segments */
    segmentsPathString() {
      const { x, y } = this
      return area()
        .x(([time]) => x(time))
        .y0(y(0))
        .y1(([, value]) => y(value))(this.segmentsData)
    },

    /** Returns an array of paths and colors for all segments */
    segmentsPathArray() {
      // used only for hr segments
      if (!this.isHRSegments) {
        return []
      }
      const { x, y } = this
      const areaGenerator = area()
        .x(([time]) => x(time))
        .y0(y(0))
        .y1(([, value]) => y(value))

      return this.segmentsData.reduce((acc, [timeStart, powerStart, , , , color], i, arr) => {
        const even = i % 2

        if (even) {
          return acc
        }

        const [timeEnd, powerEnd] = arr[i + 1]
        acc.push({
          d: areaGenerator([
            [timeStart, powerStart],
            [timeEnd, powerEnd]
          ]),
          color
        })
        return acc
      }, [])
    },

    sectionLine() {
      if (!this.mainWork.isDetected) {
        return ""
      }
      const { startTime, endTime, posY } = this.mainWork
      const arr = [
        [startTime - 0.4, posY - 12],
        [startTime - 0.4, posY - 6],
        [startTime - 0.4, posY - 4],
        [startTime - 0.3, posY - 2],
        [startTime - 0.2, posY - 1],
        [startTime, posY],
        [endTime, posY],
        [endTime + 0.2, posY - 1],
        [endTime + 0.3, posY - 2],
        [endTime + 0.4, posY - 4],
        [endTime + 0.4, posY - 6],
        [endTime + 0.4, posY - 12]
      ]
      return line()
        .x(([time]) => this.x(time))
        .y(([, value]) => this.y(value))(arr)
    },

    wBalLine() {
      const { height, padding } = this

      const x = scaleLinear()
        .domain(extent(this.wBalLineData, ([time]) => time))
        .range([padding.left, this.width - padding.right])

      const wBalMaxValue = this.wPrime / 100

      const y = scaleLinear()
        .domain([0, wBalMaxValue])
        .range([height - padding.bottom, padding.top])

      return line()
        .x(([time]) => x(time))
        .y(([, value]) => y(value))(this.wBalLineData)
    }
  },
  methods: {
    isMainWork(intervalType) {
      return INTERVALS_STYLES.BOLD.indexOf(intervalType) !== -1
    },

    onHoverMainWorkText(event) {
      if (event.type === "mouseout") {
        this.activatePolygons(null, null)
      } else {
        this.activatePolygons(this.mainWork.startIndex, this.mainWork.endIndex)
      }
    },

    render() {
      const { x, y, width, height, padding } = this

      this.svg = select(`#workout-svg-${this.id}`)

      const allValues = [0, 25, 50, 75, 100, 125, 150, 175, 200]
      const oddValues = [25, 75, 125, 175]
      const isCenterLine = d => d === 100

      const yAxisLabels = g =>
        g
          .attr("transform", `translate(${padding.left},0)`)
          .call(
            axisLeft(y)
              .tickFormat(value => `${value}%`)
              .tickValues(allValues)
          )
          .call(g => g.select(".domain").remove())
          .call(g =>
            g.selectAll("text").each((value, index, elements) => {
              if (oddValues.indexOf(value) !== -1) {
                elements[index].remove()
              }
            })
          )
          .call(g => g.selectAll(".tick line").remove())
          .call(g =>
            g
              .selectAll(".tick text")
              .attr("class", "workout-details-chart-label")
              .attr("color", value => (isCenterLine(value) ? "#2B2C2E" : LABELS_COLOR))
              .attr("font-weight", value => (isCenterLine(value) ? "bold" : "normal"))
          )

      const xAxis = g =>
        g
          .attr("transform", `translate(0, ${height - padding.bottom})`)
          .call(axisBottom(x).ticks(width / (this.isMobile ? 50 : 100)))
          .call(g => g.select(".domain").remove())
          .call(g => g.selectAll("line").attr("stroke", LABELS_COLOR))
          .call(g =>
            g
              .selectAll("text")
              .attr("class", "workout-details-chart-label")
              .attr("color", LABELS_COLOR)
          )

      const svg = select(`#workout-axis-${this.id}`)

      svg.selectAll("g").remove()

      svg.append("g").call(yAxisLabels)
      svg
        .append("g")
        .call(xAxis)
        .attr("class", "x-axis")
    },

    onResize() {
      this.isMobile = isMobile()
      if (!this.$refs.chart) return

      this.fullWidth = this.$refs.chart.clientWidth
      this.fullHeight = this.isMobile ? this.options.mobileHeight : this.options.height
      this.render()
    },

    cutMaxLength(event, value) {
      const el = event.target

      if (el.value.length > el.maxLength) {
        this[value] = el.value.slice(0, el.maxLength)
      }
    }
  },
  mounted() {
    this.debouncedScale = debounce(() => this.onResize(), 100)

    window.addEventListener("resize", this.debouncedScale)

    if (this.showMainWork) {
      try {
        // detect main work
        const mainWorkIndexes = this.segments.reduce((acc, segment, index) => {
          const [, , , , , intervalType] = segment
          if (this.isMainWork(intervalType)) {
            if (!acc.length || index - acc[acc.length - 1] === 1) {
              acc.push(index)
            }
          }
          return acc
        }, [])

        if (mainWorkIndexes.length > 1) {
          this.mainWork.startIndex = Math.min.apply(null, mainWorkIndexes)
          this.mainWork.endIndex = Math.max.apply(null, mainWorkIndexes)
          this.mainWork.startTime = this.segmentsData.find(([, , , , index]) => this.mainWork.startIndex === index)[0]
          this.mainWork.endTime =
            this.segmentsData.find(([, , , , index]) => this.mainWork.endIndex === index)[0] +
            this.segments[this.mainWork.endIndex][0]
          const powerValues = this.segments
            .slice(this.mainWork.startIndex, this.mainWork.endIndex + 1)
            .map(([, start, end]) => end || start)
          this.mainWork.posY = Math.max.apply(null, powerValues) + 6
          const yellowLinePos = this.maxValueY / 2
          if (this.mainWork.posY >= yellowLinePos && this.mainWork.posY <= yellowLinePos + 5) {
            this.mainWork.posY = yellowLinePos + 6
          }
          this.mainWork.isDetected = true
        }
      } catch (e) {
        Sentry.configureScope(scope => {
          scope.setContext("workout", { id: this.id })
          Sentry.captureException(e)
        })
      }
    }

    // The chart element does not exist in some cases, check it
    if (this.$refs.chart) {
      this.onResize()
    }
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedScale)
    this.removeHoverPolygons()
  }
})
</script>

<style lang="scss">
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.workout-details-chart-label {
  font-family: "Poppins", sans-serif;
  font-size: 16px;

  @include media("<tablet") {
    font-size: 12px;
  }
}

.workout-details-chart-axis {
  .x-axis .tick:last-child line {
    transform: translate(-1px, 0);
  }
}

.workout-chart--is-lean {
  .workout-details-chart-label {
    font-size: 10px;
  }
}
</style>

<style lang="scss" scoped>
@import "@/assets/styles/variables";
@import "@/assets/styles/mixins";

.main-work:hover {
  path {
    stroke: #2c68de;
  }
  text {
    fill: #2c68de;
  }
}
.workout-chart {
  width: 100%;
  padding-top: 2px;
}

.wbal-settings-panel {
  display: flex;
  justify-content: flex-end;
  margin-right: 15px;

  @include media("<tablet") {
    font-size: 12px;
    margin-bottom: 20px;
  }
}
</style>
